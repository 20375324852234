import React from "react";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import Hero from "../components/pageContent/index/hero";
import Layout from "../components/layout/layout";
import ArticlePreview from "../components/article-preview";
import { useStaticQuery, graphql } from "gatsby";
import "../allSass/main.css";
import IndexBody from "../components/pageContent/index/body";

const Index = () => {
  const query = useStaticQuery(graphql`
    query HomeQuery {
      allContentfulHeaderPhotos {
        edges {
          node {
            photos {
              fluid(maxWidth: 1980, maxHeight: 1280) {
                ...GatsbyContentfulFluid
              }
              title
            }
            title
          }
        }
      }
    }
  `);
  // ...GatsbyContentfulFluid_tracedSVG

  // const posts = query.allContentfulBlogPost.edges;
  // const [author] = query.allContentfulPerson.edges;

  // const silber = query.contentfulAsset;
  const pictures = query.allContentfulHeaderPhotos.edges[1].node.photos;
  const bottomPictures = query.allContentfulHeaderPhotos.edges[0].node.photos;
  return (
    <Layout>
      <div style={{ background: "#fff" }}>
        <Helmet title='My ADU Story' />
        <Hero data={pictures} />
        <IndexBody pictures={bottomPictures} />
      </div>
    </Layout>
  );
};

export default Index;
