import styled from 'styled-components'
import { variables } from '../variables'

export default styled.h3`
    font-size: 1em;
    font-weight: 750;
    margin-bottom: 1.5rem;
    text-align: center;
    a {
      height: 20px;
      color: ${variables.colorGreyDark};
      transition: all .2s;
      text-decoration: underline;
      font-size: 1.5em;
      font-weight: 750;
    }
    a:hover {
      color: ${variables.colorGreyLight3};
    }
    
`