import React, { useState } from "react";
import Img from "gatsby-image";
import Fade from "react-reveal/Fade";
import moment from "moment";
import { Link } from "gatsby";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

const Hero = ({ data }) => {
  const [checked, setChecked] = useState(false);
  SwiperCore.use([Autoplay, Pagination, Navigation]);

  return (
    <div className='hero'>
      <Swiper style={{ padding: "0" }} pagination={true} autoplay={{ delay: 5000, disableOnInteraction: false }}>
        {data.map((item) => {
          return (
            <SwiperSlide>
              <Img className='heroImage' alt={item.title} fluid={item.fluid} />
              {/* <Img style={{ filter: "grayscale(100%)" }} className='heroImage' alt={item.title} fluid={item.fluid} /> */}
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div className='heroWrapper' style={{ zIndex: "3" }}>
        <div className='heroDetails'>
          <Fade left delay={500}>
            <h1 className='heroHeadline'>See what an ADU can do for you</h1>
            <p className='heroText'>
              <h2 style={{ color: "white" }}>Learn how to finance and build your ADU fast</h2>
            </p>
          </Fade>
          <Fade bottom delay={1500}>
            <div className='cta-container'>
              <button className='cta cta-main'>
                <Link style={{ textDecoration: "none" }} to='/contact'>
                  Setup ADU consult
                </Link>
              </button>
              <button className='cta cta-secondary'>
                <Link style={{ textDecoration: "none" }} to='/blog/what-is-an-ADU'>
                  What's an ADU?
                </Link>
              </button>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};
export default Hero;
