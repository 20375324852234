import styled, { css } from "styled-components";
import { variables } from "../variables";
import { device } from "../mediaQueries";

export default styled.div`
  /* grid-template-columns: repeat(auto-fit, minmax(270px, 1fr)); */

  h1 {
    text-align: center;
    font-size: 40px;
    margin-bottom: 2rem;
  }

  .about {
    max-width: 800px;
    margin: 0 auto;
    padding: 4rem 0;
    background-color: ${variables.colorWhite};

    p {
      margin-bottom: 2rem;
    }

    .cta {
      margin: 1rem auto;
    }
  }

  .whatToExpect {
    padding: 8rem 0;
    background-color: ${variables.colorGreyLight2};

    .wrapper {
      display: flex;
      align-items: center;
    }

    .textContainer {
      width: 30%;
    }

    .videoContainer {
      width: 70%;

      .video {
        /* background-color: blue; */
        width: 80%;
        height: 400px;
        margin: 0 auto;
      }
    }

    h1 {
      text-align: left;
    }

    @media (max-width: 1100px) {
      .wrapper {
        flex-direction: column;
      }

      .textContainer {
        width: auto !important;
        max-width: 700px;
        margin: 0 auto 2rem;
        p {
          padding: 1rem;
        }
      }

      .videoContainer {
        margin: 0 auto;
        width: 100%;

        .video {
          width: 90%;
        }
      }
    }
  }

  .possibilities {
    /* padding: 6rem 0; */
    display: flex;

    .possib-description {
      width: 50%;
      height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      * {
        margin-bottom: 1.5rem;
      }
    }

    .possib-carousel {
      width: 50%;
      height: 600px;
      /* background-color: blue; */
      overflow: hidden;
    }

    @media (max-width: 1100px) {
      flex-direction: column;
      .possib-description {
        width: 100%;
        margin: 0 auto;
        padding: 0 1rem;
      }

      .possib-carousel {
        margin: 0 auto;
        width: 100vw !important;
      }
    }
  }

  .testimonials {
    transform: skew(-15deg);
    /* display: grid; */
    /* grid-template-columns: 2fr 3fr; */
    margin: 4rem 10rem;
    background-color: ${variables.colorPrimaryDark};

    @media (max-width: 1650px) {
      margin: 0;
      transform: skew(0deg);
      .container {
        transform: skew(0deg);
      }
    }

    .container {
      @media (max-width: 1650px) {
        transform: skew(0deg);
      }
      transform: skew(15deg);

      max-width: 1200px;
      margin: 0 auto;
      padding: 2rem 0;
      display: flex;
      flex-direction: column;
      color: white;
      justify-content: center;
      align-items: center;

      h1 {
        max-width: 500px;
        margin: 2rem 0;
      }
    }

    .container-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      @media (max-width: 1000px) {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
      }
      grid-gap: 2rem;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 4rem 1rem;
      .card-testimonial {
        background-color: white;
        color: ${variables.colorGreyDark2};
        padding: 2rem 1rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        display: flex;
        flex-direction: column;
        max-width: 500px;

        h2 {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 1rem;
        }
        .testimonial-text {
          margin-bottom: 2rem;
          font-size: 20px;
        }
        transition: all 0.5s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
`;
