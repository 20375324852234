import React from "react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import Img from "gatsby-image";
import { Link } from "gatsby";
import StyledIndexPage from "../../../styled-components/Body/StyledIndexPage";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

const Body = ({ pictures }) => {
  return (
    <StyledIndexPage>
      <section className='about wrapper'>
        <h1>We make owning an ADU a reality</h1>
        <p>
          My ADU Story is dedicated to showing the limitless possibilities there are with accessory dwelling units (also known as ADUs, in-law
          suites, casitas). Whether you’re looking to build another stream of income, or wanting to bring your family closer, we've got you
          covered.
        </p>
        {/* <p>
          Officia cillum voluptate elit cillum sint. Eiusmod aute pariatur nulla exercitation occaecat laborum incididunt ea veniam ea. Officia
          cillum voluptate elit cillum sint. Eiusmod aute pariatur nulla exercitation occaecat laborum incididunt ea veniam ea. Officia cillum
          voluptate elit cillum sint. Eiusmod aute pariatur nulla exercitation occaecat laborum incididunt ea veniam ea. Officia cillum voluptate
          elit cillum sint. Eiusmod aute pariatur nulla exercitation occaecat laborum incididunt ea veniam ea.
        </p> */}
        <div className='cta-container'>
          <button className='cta cta-main'>
            <Link style={{ textDecoration: "none" }} to='/contact'>
              Who We Are
            </Link>
          </button>
        </div>
      </section>
      <section className='whatToExpect '>
        <div className='wrapper'>
          <div className='textContainer'>
            <h1>What to expect</h1>
            <p>
              From today to the day you step foot in your ADU, there's a lot to take on. That's why we're here to guide to through the process,
              every step of the way.
            </p>
          </div>
          <div className='videoContainer'>
            <div className='video'>
              <iframe
                // className='video'
                style={{ width: "100%", height: "100%" }}
                // width='560'
                // height='315'
                src='https://www.youtube.com/embed/4Fs6A2tupno'
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className='testimonials'>
        <div className='container'>
          <h1>What Our Clients Are Saying</h1>
          <div className='container-grid'>
            <div className='card-testimonial'>
              <p className='testimonial-text'>“It was great to know and learn about the process from a family that has already done this.”</p>
              <b>- Amanda S.</b>
            </div>
            <div className='card-testimonial'>
              <p className='testimonial-text'>“The ADU is a real income opportunity. My ADU Story is a great resource”</p>
              <b>- Simone L.</b>
            </div>
            <div className='card-testimonial'>
              <p className='testimonial-text'>“I got great ideas on my project from My ADU Story. I just started construction!”</p>
              <b>- Don G.</b>
            </div>
          </div>
        </div>
      </section>
      <section className='possibilities'>
        <div className='possib-description'>
          <h1>The Possibilities</h1>
          <p>ADUs come in all shapes and sizes, and we're ready to help you find your match!</p>
          <div className='cta-container'>
            <button className='cta cta-main'>
              <Link style={{ textDecoration: "none" }} to='/contact'>
                Contact Us
              </Link>
            </button>
          </div>
        </div>
        <div className='possib-carousel'>
          <Swiper style={{ padding: "0" }} pagination={true} autoplay={{ delay: 5000, disableOnInteraction: false }}>
            {pictures.map((item) => {
              return (
                <SwiperSlide>
                  <Img alt={item.title} fluid={item.fluid} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </section>
    </StyledIndexPage>
  );
};

export default Body;
