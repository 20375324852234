import React, { Fragment } from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { createGlobalStyle } from "styled-components";
import StyledPageLink from "../styled-components/shared/StyledPageLink";
import StyledArticlePreview from "../styled-components/Body/StyledArticlePreview";

const GlobalStyles = createGlobalStyle`
body {
  background: #fff;
}

ul.article-list-blog {
  background: #fff;
}
`;
// import styles from './article-preview.module.css'

export default ({ article, showMore }) => {
  // const theString = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores adipisci laboriosam iste vitae nam, placeat temporibus reiciendis ex earum amet ut, quis quae iure recusandae. Officia earum nemo repellat tempora.'

  const description = article.description.description;

  const parseBlogText = () => {
    let period = description.indexOf(".") > -1 ? description.indexOf(".") : 1000;
    let exclamationPoint = description.indexOf("!") > -1 ? description.indexOf("!") : 1000;
    let questionMark = description.indexOf("?") > -1 ? description.indexOf("?") : 1000;
    // console.log(Math.min(period, exclamationPoint, questionMark))
    return Math.min(period, exclamationPoint, questionMark);
  };
  // parseBlogText()

  return (
    <StyledArticlePreview>
      <GlobalStyles />
      <Img alt='' fluid={article.heroImage.fluid} />
      <small>{article.publishDate}</small>
      <StyledPageLink>
        <Link style={{ height: "10px" }} to={`/blog/${article.slug}`}>
          {article.title}
        </Link>
      </StyledPageLink>

      {showMore ? (
        <Fragment>
          {/* <hr style={{ marginTop: '1.5rem' }} /> */}
          <div
            style={{ marginBottom: "1rem" }}
            dangerouslySetInnerHTML={{
              __html: article.description.childMarkdownRemark.html,
            }}
          />
        </Fragment>
      ) : (
        <Fragment>
          {/* <hr style={{ marginTop: '1.5rem' }} /> */}
          <p style={{ marginBottom: "1rem" }}>{description.slice(0, parseBlogText())}... </p>
          <Link style={{ color: "#4597cb", textAlign: "center", display: "block", margin: "0 auto" }} to={`/blog/${article.slug}`}>
            Read More &rarr;{" "}
          </Link>
        </Fragment>
      )}
    </StyledArticlePreview>
  );
};
