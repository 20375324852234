import styled from 'styled-components'
import { variables } from '../variables'

export default styled.div`


h3 {
    text-align: center;
}

small {
// margin: 0 auto;
    display: block;
    text-align: center;
    margin: 0 auto;
    padding: 1rem 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}

`